<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">User Role: <small>{{ traineeRole.title }}</small></h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors"></danger-alert>

    <div class="form-group text-left">
      <label for="trainee_role_title">User Role Title</label>
      <input type="text" class="form-control" id="trainee_role_title" v-model="traineeRole.title" placeholder="Title">
    </div>

    <div class="footer float-right">
      <div class="btn-group">
        <router-link :to="{ name: 'TraineeRoleIndex' }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
        <button v-on:click="createTraineeRole" type="button" class="btn btn-success">Create Trainee Role</button>
      </div>
    </div>

    <br>

  </div>
</template>

<script>
import { TraineeRoleService } from '@/common/services/trainee_role.service';
import DangerAlert from '@/common/components/danger-alert.vue';

export default {
  name: 'LanguageNew',
  components: {
    'danger-alert': DangerAlert,
  },
  data() {
    return {
      traineeRole: {
        title: '',
      },
      errors: [],
    };
  },
  methods: {
    createTraineeRole() {
      TraineeRoleService.create(this.traineeRole)
        .then(({ data }) => {
          this.$router.push({ name: 'TraineeRoleShow', params: { id: data.id } }, () => {
            this.$notify({
              title: 'Created User Role',
              text: `Successfully created ${data.title}!`,
              type: 'success',
              width: 350,
            });
          });
        })
        .catch((err) => { this.errors = err.response.data.error; });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/trainee_roles";
</style>
